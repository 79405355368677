import { Box, colors, shippoTheme, styled } from '@goshippo/components';

import CountUp from 'react-countup';

import { ChartContainer, ChartContainerProps } from './GridContainer';

export const CHART_DIMENSIONS = {
  big: {
    height: 400,
    width: 800,
  },
  default: {
    height: 200,
    width: 800,
  },
  small: {
    height: 200,
    width: 382,
  },
};

type Props = {
  data: {
    label: string;
    type: 'currency' | 'number' | 'string';
    value: number | string;
  }[];
} & Pick<ChartContainerProps, 'description' | 'empty' | 'title'>;

const Data = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${shippoTheme.spacing(1)};
  width: fit-content;
  padding: ${shippoTheme.spacing(1.5)};
  margin: ${shippoTheme.spacing(1)};
  background: ${colors.gray20};
  border-radius: ${shippoTheme.spacing(1)};
  ${shippoTheme.breakpoints.down('xl')} {
    width: 100%;
  }
`;

const Label = styled('span')`
  color: ${colors.gray100};
  font-weight: 900;
  font-size: 0.9rem;
`;

const Value = styled(CountUp)`
  color: ${colors.gray100};
`;

const StyledContainer = styled(Box)`
  height: ${CHART_DIMENSIONS.small.height}px;
  min-width: ${CHART_DIMENSIONS.small.width}px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${shippoTheme.breakpoints.between('md', 'xl')} {
    flex-direction: column;
    min-width: unset;
    > div {
      width: calc(100% - 16px);
      margin: 8px auto;
    }
  }
  ${shippoTheme.breakpoints.between(601, 'md')} {
    flex-direction: column;
    min-width: 300px;
  }
`;

export const StatsChart = ({ data, description, empty, title }: Props) => (
  <ChartContainer description={description} dimension="small" empty={empty} title={title}>
    <StyledContainer>
      {data.map(({ label, type, value }) => (
        <Data key={label}>
          <Label>{label}</Label>
          {type === 'number' || type === 'currency' ? (
            <Value duration={2} end={value as number} />
          ) : (
            value
          )}
        </Data>
      ))}
    </StyledContainer>
  </ChartContainer>
);
