import { BlockOutlined, colors, shippoTheme, styled } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

export const CHART_DIMENSIONS = {
  big: {
    height: 400,
    width: 800,
  },
  default: {
    height: 200,
    width: 800,
  },
  small: {
    height: 200,
    width: 462,
  },
};

export type ChartContainerProps = {
  children: React.ReactNode;
  description: string;
  dimension?: keyof typeof CHART_DIMENSIONS;
  empty?: boolean;
  title: string;
};

const Container = styled('div')`
  padding: ${shippoTheme.spacing(1)};
  border-radius: ${shippoTheme.spacing(1)};
  width: 100%;
  border: 1px solid ${colors.borders};
`;

const Title = styled('h2')`
  font-size: 1.15rem;
  margin: 0 8px;
  color: ${colors.gray100};
`;

const Description = styled('span')`
  font-size: 0.85rem;
  margin: 0 8px;
  color: ${colors.gray60};
`;

const Message = styled('span')`
  text-align: center;
  font-size: 0.9rem;
`;

const ChartStub = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${shippoTheme.spacing(2)};

  background: ${colors.gray20};
  border-radius: ${shippoTheme.spacing(1)};

  padding: ${shippoTheme.spacing(1)};
  ${shippoTheme.breakpoints.down('xl')} {
    width: 100%;
  }
`;

const ChartStatus = ({
  children,
  dimension = 'default',
  empty,
}: Pick<ChartContainerProps, 'children' | 'dimension' | 'empty'>) => {
  const { t } = useTranslation('overview');

  const stubStyle = {
    height: CHART_DIMENSIONS[dimension].height,
    width: CHART_DIMENSIONS[dimension].width,
  };

  if (empty)
    return (
      <ChartStub sx={{ background: colors.gray20, ...stubStyle }}>
        <BlockOutlined fontSize="large" />
        <Message>{t('empty.message')}</Message>
      </ChartStub>
    );

  return <>{children}</>;
};

export const ChartContainer = ({
  children,
  description,
  dimension,
  empty,
  title,
}: ChartContainerProps) => (
  <Container>
    <Title>{title}</Title>
    <Description>{description}</Description>
    <ChartStatus dimension={dimension} empty={empty}>
      {children}
    </ChartStatus>
  </Container>
);
