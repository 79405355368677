import { useEffect, useState } from 'react';

import { Box, DataGridPro, DataGridProProps, colors, styled } from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { formatAmount } from '~/src/utils/format';
import { StatsChart } from '~/src/views/FulfillmentReports/pages/Simulate/components/StatsChart';
import {
  ServiceLevelAnalysis,
  ShippoOrderHistoryComparison,
  ShippoOrderHistoryRow,
} from '~/src/views/FulfillmentReports/pages/Simulate/services/SimulationService';

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;

const GRAPH_COLORS = {
  fedex_2day: '#FFC624',
  fedex_home_delivery: '#25B195',
  order_history: '#189306',
  usps_ground_advantage: '#046f7c',
  usps_priority_mail: '#ef8209',
};

type Columns = DataGridProProps<ShippoOrderHistoryRow>['columns'];

const SalesSimulateResultsPageContext = () => {
  const { t } = useTranslation('simulate', { keyPrefix: 'sales' });
  const [data, setData] = useState<ShippoOrderHistoryComparison>();

  useEffect(() => {
    const simulationResultData = sessionStorage.getItem('simulationResultData');
    if (simulationResultData) {
      setData(JSON.parse(simulationResultData));
    }
  }, []);

  if (!data) return <div>Whoops. Something went awfully wrong.</div>;

  const totalCostBarGraphData = () => {
    const graphData = data.service_level_analysis.map((sl: ServiceLevelAnalysis) => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fill: GRAPH_COLORS[sl.service_level],
      name: sl.name,
      total_cost: sl.total_cost,
    }));
    graphData.push({
      fill: GRAPH_COLORS.order_history,
      name: 'Original',
      total_cost: data?.order_history_analysis.total_cost,
    });
    return graphData;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomLabel = (props: any) => {
    const { height, value, width, x, y } = props;
    const customY =
      height < 20 ? y - height / 2 : height < 40 && height > 20 ? y + height - 10 : y + 30;
    return (
      <text
        x={x + width / 2}
        y={customY}
        fontWeight="bold"
        fontSize={12}
        textAnchor="middle"
        fill={colors.text.default}
      >
        {formatAmount(value)}
      </text>
    );
  };

  const serviceLevelGrid = (serviceLevel: ServiceLevelAnalysis) => (
    <StatsChart
      data={[
        {
          label: 'average cost',
          type: 'currency',
          value: serviceLevel.average_cost_per_shipment,
        },
        {
          label: 'total cost',
          type: 'currency',
          value: serviceLevel.total_cost,
        },
        {
          label: 'average transit time',
          type: 'string',
          value: serviceLevel.average_transit_time,
        },
        {
          label: 'unmatched rows due to weight',
          type: 'currency',
          value: serviceLevel.unmatched_rows,
        },
      ]}
      description={`Summary for ${serviceLevel.name}`}
      title={t('serviceLevel', { serviceLevel: serviceLevel.name })}
    />
  );

  const createColumns = () => {
    const columns: Columns = [
      {
        field: 'tracking_id',
        headerName: 'tracking_id',
        renderCell: (params) => params.row.tracking_id,
        width: 150,
      },
      {
        field: 'carrier',
        headerName: 'carrier',
        renderCell: (params) => params.row.carrier,
        width: 100,
      },
      {
        field: 'service',
        headerName: 'service',
        renderCell: (params) => params.row.service,
        width: 100,
      },
      {
        field: 'source_zip',
        headerName: 'source_zip',
        renderCell: (params) => params.row.source_zip,
        width: 100,
      },
      {
        field: 'receiver_zip',
        headerName: 'receiver_zip',
        renderCell: (params) => params.row.receiver_zip,
        width: 100,
      },
      {
        field: 'length',
        headerName: 'length',
        renderCell: (params) => params.row.length,
        width: 100,
      },
      {
        field: 'width',
        headerName: 'width',
        renderCell: (params) => params.row.width,
        width: 100,
      },
      {
        field: 'height',
        headerName: 'height',
        renderCell: (params) => params.row.height,
        width: 100,
      },
      {
        field: 'weight',
        headerName: 'weight',
        renderCell: (params) => params.row.weight,
        width: 100,
      },
      {
        field: 'prospect_actual_amount',
        headerName: 'prospect_actual_amount',
        renderCell: (params) => params.row.prospect_actual_amount,
        width: 200,
      },
    ];
    data.service_level_analysis.forEach((sl: ServiceLevelAnalysis) => {
      const sl_rate = `${sl.service_level}_rate`;
      columns.push({
        field: sl_rate,
        headerName: sl_rate,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        renderCell: (params) => params.row[sl_rate],
        width: 200,
      });
    });

    return columns;
  };

  return (
    <Container>
      <Box sx={{ height: '100%', width: '100%' }}>
        <StatsChart
          data={[
            {
              label: 'average cost',
              type: 'currency',
              value: data.order_history_analysis.average_cost_per_shipment,
            },
            {
              label: 'average distance',
              type: 'number',
              value: data.order_history_analysis.average_distance,
            },
            {
              label: 'average zone',
              type: 'number',
              value: data.order_history_analysis.average_zone,
            },
            {
              label: 'total cost',
              type: 'number',
              value: data.order_history_analysis.total_cost,
            },
            {
              label: 'total # of shipments compared',
              type: 'number',
              value: data.order_history_analysis.rows_processed,
            },
            {
              label: 'total # of shipments',
              type: 'number',
              value: data.order_history_analysis.total_rows,
            },
          ]}
          description={`Summary for order history`}
          title={'Order history analysis'}
        />
        {data.service_level_analysis.map((serviceLevel) => serviceLevelGrid(serviceLevel))}
        <DataGridPro
          autoHeight
          columns={createColumns()}
          getRowId={(row) => row.tracking_id}
          headerHeight={32}
          rows={data.combined_analysis}
          hideFooter
        />
      </Box>
      <Box borderTop={'solid'} borderColor={'#DFE8EC'}>
        <Box mb={3} ml={1} mt={3} style={{ fontWeight: 'bold' }}>
          Total cost across service levels
        </Box>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={totalCostBarGraphData()}
            margin={{
              bottom: 20,
              left: 35,
              right: 30,
              top: 5,
            }}
            style={{ alignSelf: 'center' }}
          >
            <XAxis dataKey="name" />
            <YAxis
              tickLine={false}
              axisLine={false}
              tick={{ fill: `${colors.text.default}`, fontSize: '0.75rem' }}
              tickMargin={20}
            />
            <Bar dataKey="total_cost" radius={[8, 8, 0, 0]}>
              <LabelList dataKey="total_cost" content={<CustomLabel />} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Container>
  );
};

const SalesSimulationResultsPage = () => <SalesSimulateResultsPageContext />;

// eslint-disable-next-line import/no-default-export
export default SalesSimulationResultsPage;
