import { Suspense } from 'react';

import { Route, Routes } from 'react-router-dom';

import { APIConfigRoutes } from '~/src/views/APIConfiguration/APIConfigurationRoutes';
import { AccountRoutes } from '~/src/views/Account/AccountRoutes';
import { ActivityRoutes } from '~/src/views/Activity/ActivityRoutes';
import { FulfillmentNetworkRoutes } from '~/src/views/FulfillmentNetwork/FulfillmentNetworkRoutes';
import { FulfillmentReportsRoutes } from '~/src/views/FulfillmentReports/FulfillmentReportsRoutes';
import SalesSimulationResultsPage from '~/src/views/FulfillmentReports/pages/Simulate/SalesSimulateResultsPage';
import { salesSimulationFullPage } from '~/src/views/FulfillmentReports/pages/Simulate/constants/links';
import { MicroFrontendRoutes } from '~/src/views/MicroFrontend/MicroFrontendRoutes';
import { ReconciliationRoutes } from '~/src/views/Reconciliation/ReconciliationRoutes';

import { AuthGuard } from './AuthGuard';
import { DefaultScreenSkeleton } from './DefaultScreenSkeleton';
import { ResendVerification } from './EmailVerify/ResendVerification';
import { ResendVerificationSuccess } from './EmailVerify/ResendVerificationSuccess';
import { FullWidthLayoutSkeleton } from './FullWidthLayoutSkeleton';
import { Index } from './Index';
import { Layout } from './Layout/Layout';
import { StopImpersonateButton } from './StopImpersonateButton';

export const ProtectedRoutes = () => (
  <Suspense
    fallback={
      location.pathname.includes(salesSimulationFullPage) ? (
        <FullWidthLayoutSkeleton />
      ) : (
        <Layout>
          <DefaultScreenSkeleton />
        </Layout>
      )
    }
  >
    <AuthGuard>
      <>
        <StopImpersonateButton />
        <Layout>
          <Routes>
            <Route element={<ActivityRoutes />} path="/activity/*" />
            <Route element={<FulfillmentNetworkRoutes />} path="/settings/*" />
            <Route element={<APIConfigRoutes />} path="/api-config/*" />
            <Route element={<FulfillmentReportsRoutes />} path="/fulfillment-reports/*" />
            <Route element={<SalesSimulationResultsPage />} path={salesSimulationFullPage} />
            <Route element={<ReconciliationRoutes />} path="/reconciliation/*" />
            <Route element={<AccountRoutes />} path="/account/*" />
            <Route element={<MicroFrontendRoutes />} path="/micro-frontend/*" />
            <Route element={<ResendVerification />} path="/resend_verification/*" />
            <Route element={<ResendVerificationSuccess />} path="/resend_verification/success/*" />
            <Route element={<Index />} path="*" />
          </Routes>
        </Layout>
      </>
    </AuthGuard>
  </Suspense>
);
